import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useRenderPage } from 'hooks/useRenderPage';
import { fetchWizardData } from 'store/slices/claimContentSlice';
import { addPage } from 'store/slices/pageListSlice';
import { Header, LeaveFlowModal } from './3-organisms';
import { OverlayModel } from './4-habitats';

export const PageMapper = () => {
	const params = useParams();

	const dispatch = useAppDispatch();

	const [currentPage, setCurrentPage] = useState<WizardPage>();

	const claimContent = useAppSelector((state) => state.claimContent);

	const { setPageParam, getPageParam } = usePageParam();

	const [displayModal, setDisplayModal] = useState(false);
	const [triggerLogout, setTriggerLogout] = useState(false);
	const [requestedUrl, setRequestedUrl] = useState('');

	const { pages: wizardPages } = claimContent.wizardData ?? {};

	const renderPage = useRenderPage(currentPage);

	const navLinks = [
		{ action: () => toggleModel(PageNames.Policies), title: 'Forsikringer' },
		{ action: () => toggleModel(PageNames.FileClaim), title: 'Skade' },
		{ action: () => toggleModel(PageNames.Documents), title: 'Mine dokumenter' },
		{ action: () => toggleModel(PageNames.More), title: 'Mere' },
	];

	const findPageContent = (pagPath: string) => {
		return wizardPages?.find((page) => page.path === pagPath);
	};

	/**
	 * Show logout modal if the usere have been inactive for to long
	 */
	// const { triggerAutoLogout } = useAutoLogoutTimer(600000); //10min show modal for autologout
	// useEffect(() => {
	// 	if (triggerAutoLogout && !displayModal) {
	// 		toggleModel(PageNames.OpenPages);
	// 	}
	// }, [triggerAutoLogout]);
	/******************************************************* */

	useEffect(() => {
		if (!params?.flowName) return;
		dispatch(fetchWizardData(params.flowName));
	}, [params.flowName]);

	useEffect(() => {
		const initializePage = async () => {
			if (!claimContent.wizardData) return;

			const currentPath = getPageParam() ?? setPageParam(PageNames.StartPage);
			const pageContent = findPageContent(`/${currentPath}`);
			if (pageContent?.id) {
				setCurrentPage(pageContent);
				dispatch(addPage(pageContent));
			}
		};

		initializePage();
	}, [getPageParam(), claimContent]);

	const toggleModel = (link?: string, triggerLogout = false) => {
		setDisplayModal((prevState) => !prevState);
		setRequestedUrl(link ?? '');
		setTriggerLogout(triggerLogout);
	};

	return (
		<>
			<Header
				onLogoutClick={() => toggleModel(PageNames.OpenPages, true)}
				navLinks={navLinks}
				logoLink={PageNames.OpenPages}
			/>

			<OverlayModel>{renderPage}</OverlayModel>
			{displayModal && (
				<LeaveFlowModal
					setModalOpen={() => toggleModel()}
					triggerLogout={triggerLogout}
					requestedUrl={requestedUrl}
					//isAutoLogout={triggerAutoLogout}
				/>
			)}
		</>
	);
};
